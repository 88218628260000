<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Update Diploma
        <feather-icon icon="Edit2Icon" />
      </h4>
    
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>
          <b-col md="4">
            <b-form-group
              label="Diploma Name"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Course Name"
              >
                <b-form-input
                  id="blog-edit-title"
                  v-model="addCourseForm.name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Category"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules=""
              >
                <v-select
                  id="blog-edit-category"
                  v-model="addCourseForm.category_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="type"
                  :options="categoriesList"
                  :reduce="(val) => val.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="In-Link Name"
              label-for="blog-edit-slug"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="In-Link Name"
              >
                <b-form-input
                  id="blog-edit-slug"
                  v-model="addCourseForm.link_id"
                  @input="removeSpace($event)"
                  @change="checkLink"
                />
                <small
                  v-if="!validLink"
                  class="text-danger"
                >Not Valid</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="YouTube Link"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Youtube Link"
              >
                <b-form-input
                  id="video_link"
                  v-model="addCourseForm.video_link"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
      

          <b-col
            cols="12"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                Tab image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              > <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewE2"
                      :src="`https://lpcentre.com/${addCourseForm.tab_image}`"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>

                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="refPreviewE2.src = Tabimage
                            addCourseForm.tab_image=''"
                  >
                    Remove Image
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block mt-1">
                    <b-form-group
                      label="Tab Image"
                      label-for="blog-tab-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image"
                        rules="required"
                      >
                        <b-form-file
                          ref="refInputE2"
                          v-model="addCourseForm.tab_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Tab Image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="addCourseForm.alt_tab_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
            <div class="border rounded p-2">
              <h4 class="mb-1">
                Header image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >  <div class="d-inline-block">
                   <b-media-aside>
                     <b-img
                       ref="refPreviewEl"
                       :src="`https://lpcentre.com/${addCourseForm.header_image}`"
                       height="110"
                       width="170"
                       class="rounded mr-2 mb-1 mb-md-0"
                     />
                   </b-media-aside>
                   <b-button
                     v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                     variant="outline-secondary"
                     class="rounded ml-1 mt-2 text-center"
                     @click="refPreviewEl.src = Tabimage
                             addCourseForm.header_image=''
                     "
                   >
                     Remove Image
                   </b-button>
                 </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block mt-1">
                    <b-form-group
                      label="Header Image "
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Header Image "
                        rules="required"
                      >
                        <b-form-file
                          ref="refInputEl"
                          v-model="addCourseForm.header_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRenderer"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Header image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id=" alt_icob_image"
                        v-model="addCourseForm.alt_header_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
            <div class="border rounded p-2">
              <h4 class="mb-1">
                Og Graph Image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              > <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewE3"
                      :src="`https://lpcentre.com/${addCourseForm.og_image}`"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="refPreviewE3.src = Tabimage
                            addCourseForm.og_image=''
                    "
                  >
                    Remove Image
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block mt-1">
                    <b-form-group
                      label="Og Graph Image"
                      label-for="blog-tab-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image"
                      >
                        <b-form-file
                          ref="refInputE3"
                          v-model="addCourseForm.og_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab3"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Tab Image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="addCourseForm.alt_og_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
            <div class="border rounded p-2">
              <h4 class="mb-1">
              Mobile Image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewEM"
                      :src="`https://lpcentre.com/${addCourseForm.mobile_header_image}`"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0 col-12"
                    />
                  </b-media-aside>

                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="
                      refPreviewE3.src = Tabimage;
                      addCourseForm.mobile_header_image = '';
                    "
                  >
                    Remove Image
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block mt-1">
                    <b-form-group
                      label="Mobile Image"
                      label-for="blog-tab-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="mobile_header_image"
                      >
                        <b-form-file
                          ref="refInputEM"
                          v-model="addCourseForm.mobile_header_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab6"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Tab Image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="addCourseForm.alt_mobile_header_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="$router.go(-1)"
            >
              Back
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive, computed } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import Vue from 'vue'
import store from '@/store'
// Import Quill and define custom size style
import Quill from 'quill'
const SizeStyle = Quill.import('attributors/style/size')
SizeStyle.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px', '48px', '60px', '72px', '96px']
Quill.register(SizeStyle, true)

// Create a custom Image format
const BlockEmbed = Quill.import('blots/block/embed')
class ImageFormat extends BlockEmbed {
  static create(value) {
    let node = super.create(value)
    node.setAttribute('src', value.src)
    node.setAttribute('alt', value.alt || '')
    node.setAttribute('width', value.width || '')
    node.setAttribute('height', value.height || '')
    node.setAttribute('class', value.class || '')
    return node
  }

  static value(node) {
    return {
      src: node.getAttribute('src'),
      alt: node.getAttribute('alt'),
      width: node.getAttribute('width'),
      height: node.getAttribute('height'),
      class: node.getAttribute('class'),
    }
  }
}
ImageFormat.blotName = 'image'
ImageFormat.tagName = 'img'
Quill.register(ImageFormat, true)

export default {
  setup(props) {
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)
    const refInputE3 = ref(null)
    const refInputEM= ref(null)
    const refPreviewEM = ref(null)
    const refPreviewE3 = ref(null)
    const Tabimage = ref('media/svg/files/blank-image.svg')
    const { route } = useRouter()
    const { id } = route.value.params
    const validLink = ref(true)
    const checkLink = link => {
      const formData = new FormData()

      formData.append('slug', link)
      store.dispatch('courses/checkLink', formData)
        .then(response => {
          validLink.value = response.data.valid
        })
    }
    const removeSpace = target => {
      addCourseForm.value.link_id = target.toLowerCase() // LowerCase
        .replace(/\s+/g, '-') // space to -
        .replace(/&/g, '') // & to and
        .replace(/--/g, '-')
        .replace(/[.,]/g, '-')
        .replace(/[_]/g, '')
        .replace(/[!''"":=]/g, '')
        .replace(/[!?%$^~*#()+]/g, '')
    }
    //   const productId = productSlug.substring(id.lastIndexOf('-') + 1)
    const addCourseForm = ref({})
    store.dispatch('longcourses/GetCourse', { id }).then(response => {
      console.log(response?.data.data)
      store.commit('longcourses/GET_COURSE', response?.data.data)
      addCourseForm.value=response?.data.data
    })
    const categoriesList = ref([])
    store.dispatch('categories/CategoryList').then(response => {
      categoriesList.value = response.data
    })
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )

    const CourseForm = computed(() => store.state.courses.courses)
 

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const addCourseFormvalidate = ref()

    const save = () => {
      const formData = new FormData()
      if (validLink.value == true) {
        formData.append('mobile_header_image', addCourseForm.value.mobile_header_image)
          formData.append('alt_mobile_header_image', addCourseForm.value.alt_mobile_header_image)
        formData.append('name', addCourseForm.value.name)
        formData.append('id', addCourseForm.value.id)
        formData.append('tab_image', addCourseForm.value.tab_image)
        formData.append('name', addCourseForm.value.name)
        formData.append('link_id', addCourseForm.value.link_id)
        formData.append('tab_image', addCourseForm.value.tab_image)
        formData.append(' header_image', addCourseForm.value.header_image)
        formData.append('alt_tab_image', addCourseForm.value.alt_tab_image)
        formData.append('alt_header_image', addCourseForm.value.alt_header_image)
     
        if (!addCourseForm.value.video_link  || addCourseForm.value.video_link == null || addCourseForm.value.video_link == "" ) {
          // formData.append('video_link', null)
        } else {
        
          formData.append('video_link', addCourseForm.value.video_link)
        }
        formData.append('category_id', addCourseForm.value.category_id)
        formData.append('og_image', addCourseForm.value.og_image)
        formData.append('alt_og_image', addCourseForm.value.alt_og_image)
        if (addCourseForm.value.online == 1) {
          formData.append('online', 1)
        } else {
          formData.append('online', 0)
        }
        formData.append('_method', 'put')
        //  addCourseFormvalidate.value.validate().then(success => {
        //         if (success) {
        store
          .dispatch('longcourses/UpdateCourse', { id, formData })
          .then(response => {
            Vue.swal({
              icon: 'success',
              title: 'Updated!',

              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
      } else {
        Vue.swal({
          title: '',
          text: 'Please Enter Valid Link',
          icon: 'error',
          confirmButtonText: 'ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
      // }})
    }
    const { inputImageRendererTab3 } = useInputImageRenderer(
      refInputE3,
      base64 => {
        refPreviewE3.value.src = base64
      },
    )
    const { inputImageRendererTab6 } = useInputImageRenderer(
      refInputEM,
      base64 => {
        refPreviewEM.value.src = base64
      },
    )
    return {
      refInputEl,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      validLink,
      checkLink,
      id,
      CourseForm,
      refInputEM,
      refPreviewEM,
      refInputE3,
      inputImageRendererTab6,
      refPreviewE3,
      inputImageRendererTab3,
      inputImageRendererTab,
      removeSpace,

      save,
      required,
      email,
      categoriesList,
    }
  },

  components: {
    BCard,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
